import {Project} from './project';

export const PROJECT: Project[] = [
  {
    id: 'knit',
    index: 0,
    name: 'Knit',
    description: 'Een website voor onze start-up project tijdens de minor Creatieve Industrie.',
    descriptionProject: 'Een website gebouwd voor onze startup KNIT, bestaande uit 5 interdisciplinaire (CMD, Communicatie en ICT) studenten die een gemeenschappelijke passie hebben: “ouderen het gevoel geven dat ze iets kunnen bijdragen voor anderen” Uit onze passie is het concept KNIT gaan bestaan. KNIT-boxen maken voor ouderen door heel Nederland. In deze boxen zit alles wat nodig is voor het breien van een sjaal en is het een feestje om uit te pakken. Deze sjaals worden vervolgens weer verkocht op de website, waarbij een deel van de winst terug naar de ouderen gaat. \n' +
      'Op de website kunnen mensen een interest check doen of een donatie achterlaten, zodat we meer KNIT-boxen konden aanschaffen. Na 7 weken gewerkt te hebben met een hele toffe team hadden we samen een erg mooi concept neergezet.',
    websiteUrl: './assets/images/projectImages/knit/knitPC.png',
    imageUrl:
      [
        {image: './assets/images/projectImages/knit/knitWebsite.gif'}
      ],
    technic:
      [
        {type: './assets/images/logo/vue-js.svg'},
        {type: './assets/images/logo/html.svg'},
        {type: './assets/images/logo/sass.svg'},
        {type: './assets/images/logo/java.svg'},
        {type: './assets/images/logo/springboot.svg'},
        {type: './assets/images/logo/hibernate.svg'},
        {type: './assets/images/logo/maven.svg'}
      ]
  },
  {
    id: 'gamechane',
    index: 1,
    name: 'GameChane',
    description: 'Een educatief spelplatform met verschillende arrangementen bestaande uit verschillende minigames.',
    descriptionProject: 'Een educatief spelplatform gebouwd met een rankingsysteem. Bestaande uit een landingspagina, leerling- en administratie gedeelte.',
    websiteUrl: './assets/images/projectImages/GameChane/gameChane.svg',
    imageUrl:
      [
        {image: './assets/images/projectImages/GameChane/gameChaneWebsite.gif'},
        {image: './assets/images/projectImages/GameChane/gameChaneExample.svg'}
      ],
    technic:
      [
        {type: './assets/images/logo/vue-js.svg'},
        {type: './assets/images/logo/html.svg'},
        {type: './assets/images/logo/sass.svg'},
        {type: './assets/images/logo/java.svg'},
        {type: './assets/images/logo/springboot.svg'},
        {type: './assets/images/logo/hibernate.svg'},
        {type: './assets/images/logo/maven.svg'}
      ]
  },
  {
    id: 'reservatiesysteem',
    index: 2,
    name: 'Reservatie Systeem',
    description: 'Een reservatie systeem (TRS) gebouwd voor restaurant Ganbei in mijn eerste studie jaar ICT.',
    descriptionProject: 'Een tafel reserveringssysteem waarmee een reservering voor een tafel geplaatst kan worden. Het systeem bestaat uit een administratie en reservatie gedeelte.' +
      '',
    websiteUrl: './assets/images/projectImages/ReservatieSysteem/TRS.svg',
    imageUrl:
      [
        {image: './assets/images/projectImages/ReservatieSysteem/TRS.gif'}
      ],
    technic:
      [
        {type: './assets/images/logo/html.svg'},
        {type: './assets/images/logo/css.svg'},
        {type: './assets/images/logo/javascript.svg'},
        {type: './assets/images/logo/java.svg'}
      ]
  },
  {
    id: 'ganbei',
    index: 3,
    name: 'Ganbei',
    description: 'Website voor restaurant Ganbei.',
    descriptionProject: 'Mijn eerste officiële zelfgebouwde website voor restaurant Ganbei, met als uitbreiding het reservatiesysteem.',
    websiteUrl: './assets/images/projectImages/Ganbei/ganbei.svg',
    imageUrl:
      [
        {image: './assets/images/projectImages/Ganbei/ganbei.gif'}
      ],
    technic:
      [
        {type: './assets/images/logo/html.svg'},
        {type: './assets/images/logo/css.svg'},
        {type: './assets/images/logo/javascript.svg'},
        {type: './assets/images/logo/bootstrap.svg'},
      ]
  },
  {
    id: 'demolen',
    index: 4,
    name: 'De molen',
    description: 'Website voor cafetaria de Molen',
    descriptionProject: 'Website gebouwd voor cafetaria de Molen in Ede. ',
    websiteUrl: './assets/images/projectImages/deMolen/deMolen.svg',
    imageUrl:
      [
        {image: './assets/images/projectImages/deMolen/deMolen.gif'}
      ],
    technic:
      [
        {type: './assets/images/logo/html.svg'},
        {type: './assets/images/logo/css.svg'},
        {type: './assets/images/logo/javascript.svg'},
        {type: './assets/images/logo/bootstrap.svg'},
      ]
  },
  {
    id: 'portfolio',
    index: 5,
    name: 'Portfolio',
    description: 'Online portfolio',
    descriptionProject: 'Mijn eerste zelfgebouwde portfolio, waarin ik graag wil laten zien wie ik ben en wat ik kan.',
    websiteUrl: './assets/images/projectImages/portfolio/portfolio.svg',
    imageUrl:
      [
      ],
    technic:
      [
        {type: './assets/images/logo/html.svg'},
        {type: './assets/images/logo/angular.svg'},
        {type: './assets/images/logo/sass.svg'},
        {type: './assets/images/logo/typescript.svg'},

      ]
  }
];
