<div class="container">
  <h1 class="title">ERVARINGEN</h1>
  <div class="timeline">
  <div class="vtl">
    <ng-container *ngFor="let element of timeLineElements;">
      <div class="event">
      <p class="eventTitle">{{element.title}}</p>
      <p class="etxt">{{element.description}}</p>
        <div>
          <ul class="skillList">
            <li *ngFor="let s of element.skills">
              <span class="skill">{{s.skill}}</span>
            </li>
          </ul>
        </div>
        <p class="date">{{element.date}}</p>
      </div>
    </ng-container>
  </div>
  </div>
</div>

<app-to-top></app-to-top>
<app-footer></app-footer>
