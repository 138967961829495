import {Work} from './work';

export const WORK: Work[] = [
    {
      id: 'knit',
      title: 'Knit',
      description: 'Website voor onze KNIT start-up',
      technic: 'VUEJS',
      imageUrl: './assets/images/work/knitWork.svg',
      category: 'school'
    },
    {
      id: 'gamechane',
      title: 'GameChane',
      description: 'Een educatief spelplatform',
      technic: 'VUEJS',
      imageUrl: './assets/images/work/gameChane.svg',
      category: 'school'
    },
    {
      id: 'reservatiesysteem',
      title: 'Reservatiesysteem',
      description: 'Tafel reservatiesysteem TRS',
      technic: 'HTML/JAVA',
      imageUrl: './assets/images/work/trs.svg',
      category: 'school'
    }
];

export const WORKPRIVATE: Work[] = [
    {
      id: 'ganbei',
      title: 'Ganbei',
      description: 'Website gebouwd voor restaurant Ganbei',
      technic: 'BOOTSTRAP',
      imageUrl: './assets/images/work/ganbei.svg',
      category: 'private'
    },
    {
      id: 'demolen',
      title: 'Cafetaria de Molen',
      description: 'Website gebouwd voor cafetaria de Molen',
      technic: 'BOOTSTRAP',
      imageUrl: './assets/images/work/deMolen.svg',
      category: 'private'
    },
  {
    id: 'portfolio',
    title: 'Portfolio',
    description: 'Mijn portfolio',
    technic: 'Angular',
    imageUrl: './assets/images/work/portfolio.svg',
    category: 'private'
  }
];
