import {Component, OnInit} from '@angular/core';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {WORK, WORKPRIVATE} from './mock-work';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Portfolio| Lida Zhang | Mijn werk');
  }

  faChevronRight = faChevronRight;
  work = WORK;
  workPrivate = WORKPRIVATE;

  ngOnInit(): void {
    this.filterSelection('all');

    const btnContainer = document.getElementById('buttonContainer');
    const btns = btnContainer.getElementsByClassName('galleryBtn');
    let i;
    for (i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function(): void{
        const current = document.getElementsByClassName('active');
        current[0].className = current[0].className.replace(' active', '');
        this.className += ' active';
      });
    }
  }

  filterSelection(category: string): void {
    const school = document.querySelector('#elementSchool');
    const privateProject = document.getElementById('elementPrivate');

    if (category === 'school'){
      school.className = 'show';
      if (school.classList.contains('show')) {
        school.classList.remove('show');
        privateProject.className = 'column';
      }
    }
    else if (category === 'private'){
      privateProject.className = 'show';
      if (privateProject.classList.contains('show')) {
          privateProject.classList.remove('show');
          school.className = 'column';
      }
    }
    else if (category === 'all'){
      school.className = 'show';
      privateProject.className = 'show';
    }
  }

}



