import { Component, OnInit } from '@angular/core';
import { faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  faLinkedin = faLinkedin;
  faGithub = faGithub;
  faEnvelope = faEnvelope;
  faPhone = faPhone;

  constructor(private titleService: Title) {
    this.titleService.setTitle('Portfolio| Lida Zhang | Contact');
  }

  ngOnInit(): void {
  }

}
