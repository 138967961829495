import {Component, OnInit, ViewChild} from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.scss']
})
export class NavigationbarComponent implements OnInit {
  faBars = faBars;
  menuOpen: boolean;
  pageName: string;

  constructor(private router: Router) {
    // router.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd) {
    //     this.menuOpen = false;
    //   }
    // });
  }

  goToPage(pageName: string): void{
    this.menuOpen = false;
    this.router.navigate(['/' + pageName]);
  }

  ngOnInit(): void {
  }
}
