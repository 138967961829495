<div class="workContainer">
  <h1 class="title">MIJN WERK</h1>

  <div id="buttonContainer">
    <button class="galleryBtn active" (click)="filterSelection('all')"> ALLE </button>
    <div class="divider"></div>
    <button class="galleryBtn" (click)="filterSelection('school')"> STUDIE </button>
    <div class="divider"></div>
    <button class="galleryBtn" (click)="filterSelection('private')"> PRIVÉ </button>
  </div>


  <div class="galleryContainer">
    <div class="column" id="elementSchool">
        <ng-container class="content" *ngFor="let card of work;">
          <a class="linkContainer" work="selectedWork" [routerLink] ="['/project', card.id]">
          <div class="imageContainer">
            <img class="projectImage" src="{{card.imageUrl}}">
          </div>
          </a>
          <div class="projectInfo">
            <p class="projectTitle">{{card.title}}</p>
            <p class="projectDescription">{{card.description}}</p>
          </div>
          <div class="BtnContainer">
          <a class="projectBtn" [routerLink] ="['/project', card.id]">MEER INFO <fa-icon [icon]="faChevronRight" class="projectIcon"></fa-icon></a>
          </div>
          <div style="clear:both;"></div>
        </ng-container>
    </div>

    <div class="column" id="elementPrivate">
        <ng-container class="content" *ngFor="let card of workPrivate; let i = index">
          <a class="linkContainer" [routerLink] ="['/project', card.id]">
            <div class="imageContainer">
              <img class="projectImage" src="{{card.imageUrl}}">
            </div>
          </a>
          <div class="projectInfo">
            <p class="projectTitle">{{card.title}}</p>
            <p class="projectDescription">{{card.description}}</p>
          </div>
          <div class="BtnContainer">
            <a class="projectBtn" [routerLink] ="['/project', card.id]">MEER INFO <fa-icon [icon]="faChevronRight" class="projectIcon"></fa-icon></a>
          </div>
          <div style="clear:both;"></div>
        </ng-container>
      </div>
  </div>
</div>

<div style="margin-bottom: 150px"></div>

<app-to-top></app-to-top>
<app-footer id="workFooter"></app-footer>
