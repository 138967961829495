import {Component, Inject, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import { faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  faChevronRight = faChevronRight;

  constructor(@Inject(DOCUMENT) private document, private titleService: Title) {
    this.titleService.setTitle('Portfolio| Lida Zhang');
  }

  introduction =
    {
      description: 'Hi, leuk dat je een kijkje neemt op mijn portfolio! Ik ben een software Development student\n' +
      '      aan de Hoge school Utrecht. En momenteel ben ik op zoek naar een afstudeerstage. Graag wil ik je een beeld\n' +
      '      geven van wie ik ben en mijn ontwikkelingen.'
  };


  ngOnInit(): void {
    this.document.body.classList.add('bodybg-color');
  }

  ngOnDestroy(): void  {
    this.document.body.classList.remove('bodybg-color');
  }
}
