<!--Navigation-->
<header>
  <div class="logoContainer">
    <a class="logo" (click)="goToPage('/')" routerLink="/">LZ</a>
    <hr class="line">
  </div>
  <nav>
    <ul class="navBar">
      <div class="nav">
        <div [ngClass]="{ 'dropdown-after' : menuOpen}">
          <ul>

          <li><a class="navLink" (click)="goToPage('about') "routerLink="about" routerLinkActive="active-link">Over mij</a></li>
          <li><a class="navLink" (click)="goToPage('work') "routerLink="work" routerLinkActive="active-link">Mijn werk</a></li>
          <li><a class="navLink" (click)="goToPage('skills') "routerLink="skills" routerLinkActive="active-link">Vaardigheden</a></li>
          <li><a class="navLink" (click)="goToPage('experience') "routerLink="experience" routerLinkActive="active-link">Ervaringen</a></li>
          <li><a class="navLink" (click)="goToPage('contact') "routerLink="contact" routerLinkActive="active-link">Contact</a></li>
          </ul>
        </div>
        <a id="barIcon" (click)="menuOpen = !menuOpen"><fa-icon [icon]="faBars"></fa-icon></a>
        <div class="clearfix"></div>
      </div>
    </ul>
  </nav>

</header>

