<div class="container">
  <div>
    <img class="imageAbout" src="./assets/images/me.svg" alt="Me" onContextMenu="return false;">
  </div>
  <h1 class="title">EEN BEETJE OVER MIJ</h1>
  <div>
    <p class="about" id="description">Ik creeër graag waarde door middel van <mark>design</mark> en <mark>programmeren</mark></p>
  </div>
  <div>
    <div class="aboutMe">
    <p class="about">
      Hi! ik ben Lida en ik ben een 4e jaars software developer student. Momenteel ben ik bezig met mijn onderzoeksstage. Daarnaast heb ik een specialisatie in de front-end gevolgd. In mijn vrije tijd ben ik graag creatief bezig. Van designs maken en tot het in
      werkelijkheid brengen met code, dat is mijn passie! Verder hou ik erg veel van uitdaging em wil ik zoveel mogelijk van alles leren.
      Een nieuwe ervaring leren? daar zeg ik geen nee tegen.
    </p>
    <p class="about">
      Ik ben een gedreven, gemotiveerde en geduldige werker dat graag kwalitatieve werk neerzet, waar anderen ook gelukkig van worden.
    </p>
    </div>
    <button class="aboutBtn" (click)="openFile()"><fa-icon [icon]="faFile"></fa-icon> CURRICULUM VITAE</button>
  <div class="scrollContainer">
    <a (click)="scrollToElement(design)">
    <p class="centerP">Scroll naar beneden</p>
    <div class="object">
      <fa-icon [icon]="faChevronDown" class="iconChevron"></fa-icon>
    </div>
    </a>
  </div>
  </div>

  <section  id="design" class="imageContainer" #design>
    <h1 class="alineaTitle">
      UI DESIGN
    </h1>
    <p class="aboutDesign">
      Ik <mark>bedenk</mark> en <mark>ontwerp</mark> graag creatieve oplossingen
    </p>
    <div class="designContainer">
      <img class="designImg" src="./assets/images/design.svg" alt="Image" onContextMenu="return false;">
    </div>
  </section>

  <section class="imageContainer">
    <h1 class="alineaTitle">
      &lt;Programmeren/&gt;
    </h1>
    <p class="aboutDesign">
      Ik <mark>implementeer</mark> graag creatieve oplossingen
    </p>
    <div class="designContainer">
      <img class="designImg" src="./assets/images/programming.svg" alt="Image" onContextMenu="return false;">
    </div>
  </section>

  <div>
    <h1 class="testimonials">
      Wat anderen over mij zeggen
    </h1>
    <div class="designContainer">
      <img class="reviewImg" src="./assets/images/review.svg" alt="Image" onContextMenu="return false;">
    </div>
    <div class="testimonialContainer">
    <app-testimonial-slider [slides]="slides"></app-testimonial-slider>
    </div>
  </div>
</div>
<app-to-top></app-to-top>
<app-footer></app-footer>
