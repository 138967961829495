import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import { faArrowAltCircleLeft, faPhone, faHome} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faPhone = faPhone;
  faHome = faHome;

  constructor(private titleService: Title, private location: Location) {
    this.titleService.setTitle('Pagina niet gevonden');
  }

  ngOnInit(): void {
  }

  backClicked(): void {
    this.location.back();
  }
}
