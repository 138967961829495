import { Component, OnInit } from '@angular/core';
import { faFile, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {
  faFile = faFile;
  faChevronDown = faChevronDown;

  public slides = [
    { text: 'Tijdens CI heb ik een lange tijd met Lida samengewerkt aan de start-up KNIT. ' +
        'Ik heb Lida leren kennen als een heel betrouwbaar persoon om mee samen te werken. ' +
        'Ik kon altijd vertrouwen op Lida wanneer we afspraken met elkaar maakten, ' +
        'maar ook voor hulp kon ik bij Lida terecht. Wat me tijdens het samenwerken met Lida is opgevallen, ' +
        'is dat Lida ontzettend veel geduld heeft met andere mensen. ' +
        'Als CMD’er heb ik weinig kennis over ICT, Lida nam telkens de tijd en de moeite om uit te leggen ' +
        'waar ze mee bezig was. Lida is in staat om de “ingewikkelde ICT-taal” te vertalen naar een taal ' +
        'die voor een buitenstaander begrijpelijk is. Dat vind ik knap, en ik denk dat dit iets is dat ' +
        'haar onderscheid van andere ICT’ers!',
      name: 'Noor'
    },
    { text: 'Lida heb ik leren kennen tijdens het vak Creative Industries. ' +
        'Ze is een hele aardige collega die zich erg professioneel opstelt. ' +
        'Al meteen in het begin nam ze de leiding en heeft ze ons door het project geleid. ' +
        'Daarnaast nam ze feedback en de mening van de andere teamleden ter harte. ' +
        'Ook dacht ze mee met mij wanneer ik iets moeilijk vond. ' +
        'Kortom, een hele fijne collega om naast je zijde te hebben.',
      name: 'Jade'
    },
    { text: 'De samenwerking met Lida is heel fijn gelopen. ' +
        'Lida zet zich altijd 100% in en neemt initiatief in taken oppakken. ' +
        'Daarnaast brengt ze ook een fijne energie met zich mee wat het bij elkaar maakt ' +
        'tot een prettige samenwerking.',
      name: 'Josseline'
    },
    { text: '\n' +
        'Lida en ik kenden elkaar niet voor Creatief Industrie 3.0. ' +
        'Van te voren had ik een ander beeld van haar dan nu. ' +
        'Ik dacht: Lida is niet zo spraakzaam en ik vraag mij af of zij het wel fijn vindt om ' +
        'zo een aanwezig persoon zoals ik in de groep te hebben. Wat had ik het mis. ' +
        'Lida produceert zo veel in zo een korte tijd. Als je vraagt of zij ergens onderzoek naar wilt doen, ' +
        'heeft ze het gehele onderwerp van kop tot staart onderzocht in een mum van tijd. ' +
        'Daarnaast is Lida zeer intelligent. Je hoeft haar nooit iets twee keer uit te leggen. ' +
        'Zelfs al is het haar vakgebied niet, kan zij het haar eigen maken. ' +
        'Wat ik het aller fijnst aan Lida vind is haar geduld. Zij, als ict’er, ' +
        'kan in Jip en Janneke taal uitleggen hoe haar centre of expertise werkt. ' +
        'Dat maakt het gemakkelijk voor een leek als ik.  Lida wil overal bij betrokken zijn en ' +
        'dat zorgt ervoor dat ze op alle facetten kennis van het project heeft.\n',
      name: 'Brian'
    },
    {
      text: 'Lida is een harde werker die veel taken op zich neemt en deze secuur afmaakt. ' +
        'Het is ontzettend fijn samenwerken met haar, ze denkt en doet veel mee tijdens projecten. ' +
        'Lida is een echte doorzetter en een aanwinst voor ieder team, als ik de kans krijg zou ik zo ' +
        'nog veel meer met Lida willen samenwerken!',
      name: 'Willemijn'
    },
    {
      text: 'Ik heb tijdens mijn samenwerking gemerkt dat je super leergierig bent. ' +
        'Als je iets niet kon, wilde je graag ontdekken hoe het moest en stelde je veel vragen. ' +
        'Je stond open voor andermans meningen. Ook was je erg ondernemend, ' +
        'je zat nooit stil maar was constant aan het bedenken wat er nog gedaan kon worden. ' +
        'Daardoor was het fijn om met jou samen te werken, harde werker, betrokken en ruimdenkend.',
      name: 'Irene'
    },
    {
      text: 'Lida is een aanwinst voor elk team. Ze werkt hard, waardoor haar taken keurig op tijd worden afgerond, en gaat zeer zorgvuldig te werk.\n' +
        'In de projecten die ik met haar heb mogen ontwikkelen heeft Lida mij positief verrast met haar grafische vaardigheden. Daarnaast kwam ze regelmatig met goede ideeën en kon ze het team van nuttige feedback voorzien.\n' +
        'Van Lida kan je verwachten dat ze resultaat van hoge kwaliteit levert. Dit maakt haar een top teamlid om mee samen te werken.\n',
      name: 'Mart'
    },
    {
      text: 'De samenwerking met Lida verliep heel soepel. Dit kwam doordat zij goed communiceerde met mij ' +
        'en altijd snel en duidelijk reageerde. Als ik iets niet snapte legde ze me alles duidelijk uit ' +
        'en daardoor kon ik de vragen goed beantwoorden en beter mijn best doen. ' +
        'En ze had ook een goede motivatie wat heel belangrijk is als je met iemand samenwerkt. ' +
        'Ze had een doel die ze heel graag wou bereiken en daardoor werk ik ook gemotiveerd. ' +
        'En uiteindelijk heeft ze heel iets moois voor mekaar gekregen met een mooie doel op het einde. ' +
        'Het eindresultaat is dan ook geweldig.',
      name: 'Michele'
    }
    // {
    //   text: ' ',
    //   name: ' '
    // }
  ];

  constructor(private titleService: Title) {
    this.titleService.setTitle('Portfolio| Lida Zhang | Over mij');
  }

  ngOnInit(): void {
  }

  openFile(): void {
    window.open('./assets/files/CV.pdf', '_blank');
  }

  scrollToElement(element): void {
    element.scrollIntoView({behavior: 'smooth', inline: 'nearest'});
  }
}
