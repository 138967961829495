// Defines the class for the component
// Processing of the html structure like connecting to the database, interacting with other components, routing, services etc.
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'Portfolio | Lida Zhang';
}
