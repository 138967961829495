<div class="contact">
  <h1 class="title">CONTACT</h1>
  <div class="contactContainer">
  <div class="contactInformationContainer">
    <a class="email" HREF="mailto:Lpjxzhang@hotmail.com" target="_blank"><p><fa-icon [icon]="faEnvelope" class="iconEnvelope"></fa-icon>Lpjxzhang@hotmail.com</p></a>
    <a class="phone" href="tel:+31655175645"><p><fa-icon [icon]="faPhone" class="iconPhone" rotate="90"></fa-icon> 06 - 55175645</p></a>
  </div>
  <div class="contactBtnContainer">
    <a class="contactBtn" href="https://github.com/Lidazhang99" target="_blank"><fa-icon [icon]="faGithub"></fa-icon></a>
    <div class="divider"></div>
    <a class="contactBtn" href="https://www.linkedin.com/in/lida-zhang-04a225170/" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
  </div>
  </div>
  <div class="contactImgContainer">
    <img class="contactImage" src="./assets/images/contact.svg" alt="Image" onContextMenu="return false;">
  </div>
</div>

<app-footer></app-footer>
