import { Component, OnInit, Input } from '@angular/core';
import { faQuoteLeft, faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-testimonial-slider',
  templateUrl: './testimonial-slider.component.html',
  styleUrls: ['./testimonial-slider.component.scss']
})
export class TestimonialSliderComponent implements OnInit {
  faQuoteLeft = faQuoteLeft;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  @Input() slides;

  currentSlide = 0;

  constructor() { }

  ngOnInit(): void {
  }

  onPreviousClick(): void {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    // console.log('previous clicked, new current slide is: ', this.currentSlide);
  }

  onNextClick(): void {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    // console.log('next clicked, new current slide is: ', this.currentSlide);
  }

}
