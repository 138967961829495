<body class="bodybg-color">
<div class="homeContainer">
  <div class="image">
    <img class="homeImg fadeInRight" src="./assets/images/home.svg" alt="Image" onContextMenu="return false;">
  </div>
  <div class="aboutMeSection">
    <div class="clearfix"></div>
  <h1 class="h1Name">LIDA <br> ZHANG</h1>
  <div class="sectionDescription">
    <p class="description"> {{introduction.description}}</p>
  </div>
  <div class="sectionDiscover">
    <button routerLink="about" class="aboutBtn"> ONTDEK MEER OVER MIJ <fa-icon [icon]="faChevronRight" class="icon"></fa-icon> </button>
  </div>
  </div>
  </div>
</body>
