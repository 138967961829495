import { Component, OnInit } from '@angular/core';
import { faPuzzlePiece, faChartBar} from '@fortawesome/free-solid-svg-icons';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  faPuzzlePiece = faPuzzlePiece;
  faChartBar = faChartBar;

  constructor(private titleService: Title) {
    this.titleService.setTitle('Portfolio| Lida Zhang | Vaardigheden');
  }

  public competentionLeft = [
    {
      name: 'Zelfredzaam',
      type: 'zelfredzaam'
    },
    {
      name: 'Samenwerkend',
      type: 'samenwerkend'
    },
    {
      name: 'Creatief',
      type: 'creatief'
    },
    {
      name: 'Discipline',
      type: 'discipline'
    }
  ];

  public competentionRight = [
    {
      name: 'Gedreven',
      type: 'gedreven'
    },
    {
      name: 'Analytisch',
      type: 'analytisch'
    },
    {
      name: 'Plannen',
      type: 'plannenEnOrganiseren'
    },
    {
      name: 'Luisteren',
      type: 'luisteren'
    }
  ];

  public developmentLeft = [
    {
      name: 'Office',
      type: 'office'
    },
    {
      name: 'Bootstrap',
      type: 'bootstrap'
    },
    {
      name: 'HTML5',
      type: 'html5'
    },
    {
      name: 'CSS3/Sass/Scss',
      type: 'css'
    },
    {
      name: 'WordPress',
      type: 'wordpress'
    },
    {
      name: 'C#',
      type: 'c'
    },
    {
      name: 'VueJS',
      type: 'vueJS'
    },
    {
      name: 'Jupyter',
      type: 'jupyter'
    },
    {
      name: 'Oracle Apex',
      type: 'apex'
    },
    {
      name: 'Spring',
      type: 'spring'
    },
    {
      name: 'Angular',
      type: 'angular'
    },
  ];

  public developmentRight = [
    {
      name: 'Javascript',
      type: 'javascript'
    },
    {
      name: 'Java',
      type: 'java'
    },
    {
      name: 'Python',
      type: 'python'
    },
    {
      name: '.NET',
      type: 'net'
    },
    {
      name: 'Indesign/illustrator',
      type: 'indesign'
    },
    {
      name: 'Adobe XD',
      type: 'adobe'
    },
    {
      name: 'Vaadin',
      type: 'vaadin'
    },
    {
      name: 'SQL/ PLSQL',
      type: 'sql'
    },
    {
      name: 'Polymer',
      type: 'polymer'
    },
    {
      name: 'Node.js',
      type: 'node'
    },
  ];

  ngOnInit(): void {
  }

}
