import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('Portfolio| Lida Zhang | Ervaringen');
  }

  public timeLineElements = [
    {
      title: 'Onderzoeksstage',
      description: 'Front-end Comparison',
      skills: [
        {
          skill: 'Onderzoek doen/ scriptie'
        },
        {
          skill: 'Front-end frameworks (Angular, React, Vue.js, Svelte, Ember,js en Preact)'
        },
        {
          skill: 'Back-end (databeheer- en verwerking)'
        },
        {
          skill: 'Reflecteren, plannen en presenteren'
        }
      ],
      date: 'Sept 2021 - Feb 2022'
    },
    {
      title: 'Specialisatie',
      description: 'Front end & innovation - HU',
      skills: [
        {
          skill: 'Angular'
        },
        {
          skill: 'Angular Materials'
        },
        {
          skill: 'Firebase'
        },
        {
          skill: 'Multidiciplinair samenwerken (TI, SD, AI, CSC)'
        }
      ],
      date: 'Feb 2021 - Jul 2021'
    },
    {
      title: 'Minor',
      description: 'Creatieve Industrie - HU',
      skills: [
        {skill: 'Interdisciplinair samenwerken (CMD, Journalistiek, Communicatie, ICT)'},
        {skill: 'Pitchen & presenteren'},
        {skill: 'Ondernemerschap'},
        {skill: 'Doelgroep onderzoek & Gebruikerstesten'},
        {skill: 'Creatief Reflectief leiderschap'},
        {skill: 'Breedte- & diepte poot ontwikkeling'},
        {skill: 'Design Thinking & Mindsets'}],
      date: 'Sept 2020 - Feb 2021'
    },
    {
      title: 'Game Chane',
      description: 'project - HU',
      skills: [],
      date: 'Mrt 2020 - Jul 2020'
    },
    {
      title: 'Eigen projecten & Designs',
      description: 'Eigen projecten & Designs',
      skills: [],
      date: 'Sept 2018 - Sept 2020'
    },
    {
      title: 'Propedeuse HBO - ICT',
      description: 'P gehaald ',
      skills: [],
      date: 'Jul 2019'
    },
    {
      title: 'Major',
      description: 'HBO - ICT aan de hogeschool Utrecht',
      skills: [
        {skill: 'Backend & Architecture'},
        {skill: 'SQL & PLSQL'},
        {skill: 'Data science'},
        {skill: 'Low Coding platform'},
        {skill: 'Frameworks & Libraries'},
        {skill: 'Frontend '}
      ],
      date: 'Sept 2018 - Heden'
    },
    {
      title: 'VAVO',
      description: 'Dulon College (ROC A12)',
      skills: [],
      date: 'Aug 2017 - Jun 2018'
    },
    {
      title: 'HAVO (NT & NG)',
      description: 'Pallas Athene College',
      skills: [{
        skill: 'Optionele vakken: Aardrijkskunde & Kunstvak'
      }],
      date: 'Aug 2012 - Jun 2017'
    },
  ];
  ngOnInit(): void {
  }
}
