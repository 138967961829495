<div class="containerHeight">
<div class="pageNotFoundContainer">
  <h1 class="title">404</h1>
  <div>
    <p class="oopsText">Oeps, hier moet je niet zijn</p>
  </div>
</div>

  <div class="horizontalContainer">
    <div class="box">
    <fa-icon [icon]="faArrowAltCircleLeft" class="notFoundIcon"></fa-icon>
    <div>Ga terug
      <p class="errorText">
        Je ziet deze pagina omdat er iets niet goed is gegaan of omdat je een verkeerde pagina probeert te bereiken.
        <mark><a (click)="backClicked()">Ga terug naar de vorige pagina</a></mark>
      </p>
    </div>
    </div>
    <div class="box">
      <fa-icon [icon]="faPhone" class="notFoundIcon"></fa-icon>
      <div>Neem contact op
        <p class="errorText">
          Een fout gevonden? Neem contact met op en ik fix het zo snel mogelijk
        </p>
      </div>
    </div>
    <div class="box">
      <fa-icon [icon]="faHome" class="notFoundIcon"></fa-icon>
      <div>Naar de homepage
        <p class="errorText">
          Ga terug naar de <mark><a routerLink="">homepage</a></mark>
        </p>
      </div>
    </div>
  </div>
</div>
<img class="notFoundImage" src="./assets/images/pageNotFound.svg" alt="Image" onContextMenu="return false;">
<app-footer></app-footer>
