<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div *ngIf="i === currentSlide">
      <div class="carouselHeader">
        <fa-icon [icon]="faQuoteLeft"></fa-icon>
        <p class="name">{{slide.name}}</p>
        <hr class="nameLine">
      </div>
      <p class="carouselText">{{slide.text}}</p>
    </div>
  </ng-container>
</div>

<div class="controls">
  <button class="control" (click)="onPreviousClick()">
    <span><fa-icon [icon]="faChevronLeft"></fa-icon></span>
  </button>
  <button class="control" (click)="onNextClick()">
    <span><fa-icon [icon]="faChevronRight"></fa-icon></span>
  </button>
</div>




