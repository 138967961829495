<ng-container  *ngFor="let project of projects; index as i;">
  <div *ngIf="project.id === param.id">
    <div class="containerProject">
      <h1 class="title">{{project.name }}</h1>
      <div class="centerDescription">
      <p class="pDescription">{{project.description}}</p>
        <img class="projectImage" src="{{project.websiteUrl}}" onContextMenu="return false;">
      </div>
    </div>
    <div class="backgroundProject">
      <div class="containerProject">
        <div class="centerDescription">
          <p class="descriptionProject">Omschrijving</p>
          <p class="projectDescriptionText"> {{project.descriptionProject}}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <ng-container  *ngFor="let image of project.imageUrl;">
        <div class="gifContainer">
          <img class="gifImage" src="{{image.image}}" onContextMenu="return false;">
        </div>
      </ng-container>
      <h1 class="alineaTitle">
        TECHNIEKEN
      </h1>
      <div class="logoContainer">
      <ng-container  *ngFor="let type of project.technic;">
        <img class="logoImage" src="{{type.type}}" onContextMenu="return false;">
      </ng-container>
      </div>
      <div class="btnContainer">
        <button class="navBtn" (click)="prev()"><fa-icon [icon]="faChevronLeft"></fa-icon></button>
        <button class="navBtn" (click)="next()"><fa-icon [icon]="faChevronRight"></fa-icon></button>
      </div>
      <div class="space"></div>
    </div>
  </div>
</ng-container>




<app-footer></app-footer>



