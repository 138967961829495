// Reference to default libraries which are imported
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';

import { NavigationbarComponent } from './components/navigationbar/navigationbar.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { HomeComponent } from './home/home.component';
import { TestimonialSliderComponent } from './components/testimonial-slider/testimonial-slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { WorkComponent } from './work/work.component';
import { SkillsComponent } from './skills/skills.component';
import { ToTopComponent } from './components/to-top/to-top.component';
import { ExperienceComponent } from './experience/experience.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProjectComponent } from './project/project.component';

// Imported and saved into variables

// Define modules
@NgModule({
  // The reference to the components is stored, array of components created
  declarations: [
    AppComponent,
    NavigationbarComponent,
    AboutMeComponent,
    HomeComponent,
    TestimonialSliderComponent,
    FooterComponent,
    WorkComponent,
    SkillsComponent,
    ToTopComponent,
    ExperienceComponent,
    ContactComponent,
    PageNotFoundComponent,
    ProjectComponent
  ],
  // This will have the modules imported, array of modules required to be used in the application
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule
  ],
  // This will have reference to the services created
  providers: [],
  // This has reference to the services created, includes the main app component for starting the execution
  bootstrap: [AppComponent]
})
export class AppModule { }
