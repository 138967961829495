<div class="container">
  <h1 class="title">VAARDIGHEDEN</h1>
  <div class="skillsCompetention">
    <p class="center"><fa-icon [icon]="faPuzzlePiece" class="icon"></fa-icon> COMPETENTIES</p>
    <div class="skillsBar">
      <div class="columnLeft">
        <div class="bar">
        <ng-container *ngFor="let skill of competentionLeft;">
          <div [className]="'skills ' + skill.type">{{skill.name}}</div>
          <div class="break">
          </div>
        </ng-container>
        </div>
      </div>

      <div class="columnRight">
        <div class="bar">
        <ng-container *ngFor="let skill of competentionRight;">
          <div [className]="'skills ' + skill.type">{{skill.name}}</div>
          <div class="break">
          </div>
        </ng-container>
      </div>
      </div>
    </div>
  </div>

  <div class="skillsDevelopment">
    <p class="center"><fa-icon [icon]="faChartBar"></fa-icon> DEVELOPMENT & DESIGN</p>
    <div class="skillsBar">
      <div class="columnLeft">
        <div class="bar">
          <ng-container *ngFor="let skill of developmentLeft;">
            <div [className]="'skills ' + skill.type">{{skill.name}}</div>
            <div class="break">
            </div>
          </ng-container>
        </div>
      </div>

      <div class="columnRight">
        <div class="bar">
          <ng-container *ngFor="let skill of developmentRight;">
            <div [className]="'skills ' + skill.type">{{skill.name}}</div>
            <div class="break">
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
