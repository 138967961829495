import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PROJECT} from './mock-project';
import {Title} from '@angular/platform-browser';
import {Project} from './project';
import { faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private titleService: Title) {
      this.titleService.setTitle('Portfolio| Lida Zhang | Mijn projecten');
  }

  private routeSub: Subscription;

  param: any;
  projects = PROJECT;
  activeId = 0;

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.param = params;
      // console.log(this.param);
    });

    this.findId(this.param.id);
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  next(): void {
    if ( this.activeId < this.projects.length - 1 ){
      this.activeId = this.activeId + 1;
    }
    else if (this.activeId === this.projects.length - 1) {
      this.activeId = this.activeId - this.projects.length + 1;
    }
    const page = this.projects.find(x => x.index === this.activeId).id;

    this.router.navigate( ['/project/', page]);

  }

  prev(): void {
    if ( this.activeId <= this.projects.length && this.activeId >= 1){
      this.activeId = this.activeId - 1;
    }
    else if (this.activeId === this.projects.length - this.projects.length) {
      this.activeId = this.activeId + this.projects.length - 1;
    }
    const prevPage = this.projects.find(x => x.index === this.activeId).id;
    this.router.navigate(['/project/', prevPage]);
  }


  activeProjects(): Project {
    const p = this.projects[this.activeId];
    console.log(p);
    return p;

  }

  findId(id): void{
    try {
      const projectId = this.projects.find(x => x.id === id);
      if (projectId.id === id){
      }
      else {
        this.router.navigate(['/404']);

      }
    }catch (e){
      this.router.navigate(['/404']);
    }
  }

  moveUp(id): void {

  }
}
